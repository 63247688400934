import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {I18nextProvider} from "react-i18next";
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import App from './App';
import {persistor, store} from './app/store';
import i18n from "./i18nf/i18n";
import './index.css';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Suspense>
                <I18nextProvider i18n={i18n} defaultNS={'translation'}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <App/>
                        </PersistGate>
                    </Provider>
                </I18nextProvider>
            </Suspense>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();