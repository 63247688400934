import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {useDispatch} from 'react-redux';
import {ReactComponent as SoundIcon} from '../../../../assets/sound.svg';
import {SOUND_TRAINING} from "../../../../constant";
import {
    incrementFinalSequenceIndex,
    incrementHintsNumber,
    incrementMistakeNumber
} from "../../../../store/training/trainingSlice";
import {
    completeInputTrainingRef,
    hintTriggerEvent,
    HtmlImageElement,
    HtmlInputElement,
    playSound,
    removeArticles,
    revealAnswer
} from "../../../../utils/trainingUtils";
import {TrainingTypesProps} from "../../Training";
import TrainingBlock from "../TrainingBlock";
import {getBlockWidth} from "../../../../utils/globalUtils";
import TrainingInput from "./CompleteEmptySpacesInput";
import TrainingImage from '../TrainingImage';
import {motion} from "framer-motion";
import {Language} from "../../../../store/languageSlice";

const AudioTraining = ({elem, targetWord}: TrainingTypesProps) => {
    console.log('targetword: ', targetWord)
    const dispatch = useDispatch();
    const {word, imageUrl, soundUrl} = targetWord.wordTranslation.wordFrom;
    const [correct, setCorrect] = useState<boolean>(false);
    const [input, setInput] = useState<string>("");
    const [hint, setHint] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);
    const [, setCount] = useState<number>(0);

    useEffect(() => {
        setCount(c => c + 1)
    }, [setCount])

    const inputRef = useRef<HtmlInputElement>(null)
    const imageRef = useRef<HtmlImageElement>(null)

    const onChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInput(value);
        const lang = targetWord.wordTranslation.wordFrom.language as Language;
        if (removeArticles(value.toLowerCase(), lang).trim() === removeArticles(word.toLowerCase(), lang).trim()) {
            completeTraining(false)
        }
    }

    const onClickEndTraining = () => {
        if (correct) {
            resetInput();
            dispatch(incrementFinalSequenceIndex())
        } else {
            showCorrectAnswer()
        }
    }
    const completeTraining = (localSkipped: boolean) => {
        setCorrect(true)

        playSound(SOUND_TRAINING)
            .then(message => console.log(message))
            .catch(error => console.error("Error:", error));

        playSound(targetWord.wordTranslation.wordFrom.soundUrl)
            .then(message => console.log(message))
            .catch(error => console.error("Error:", error));

        completeInputTrainingRef(inputRef)
        completeInputTrainingRef(inputRef)
        if (imageRef.current) {
            imageRef.current.style.visibility = 'visible'
        }

        if (!localSkipped) {
            console.log('auto-sip')
            setTimeout(() => {
                resetInput();
                setHint(false)
                setInput("")
                setSkipped(false)
                setCorrect(false)
                dispatch(incrementFinalSequenceIndex())
            }, 1000)
        }
    }
    const hintClickTrigger = () => {
        hintTriggerEvent(setInput, word)
        dispatch(incrementHintsNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
        setHint(true);
    }

    const showCorrectAnswer = () => {
        setSkipped(true);
        revealAnswer(setInput, word, elem, imageRef)
        setInput(word);
        dispatch(incrementMistakeNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
        completeTraining(true)
    }

    const resetInput = () => {
        if (inputRef.current) {
            inputRef.current.value = ""
            inputRef.current.disabled = false;
            inputRef.current.style.border = "";
        }
        if (imageRef.current) {
            imageRef.current.style.visibility = 'visible';
        }

        setInput("");
        setCorrect(false);
        setHint(false);
        setSkipped(false);
    }

    useEffect(() => {
        setTimeout(() => {
            playSound(soundUrl).then()
        }, 500)
        if (inputRef.current) inputRef.current.focus()
    }, [soundUrl])


    console.log("currently trained word: ", elem, word)

    return (
        <TrainingBlock isSkipped={skipped} isCorrect={correct} showHint={hintClickTrigger} setInput={setInput}
                       showCorrectAnswer={showCorrectAnswer} handleSkipClick={onClickEndTraining}>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}}
                        className={`flex flex-col items-center`}>
                <TrainingImage ref={imageRef} src={imageUrl}/>
                <button type="button" value='Replay' className="m-5 flex flex-col gap-2 items-center">
                    <SoundIcon onClick={() => playSound(soundUrl)} className="w-6 h-6 text-color-big-text hover mb-1"/>
                    <TrainingInput
                        width={getBlockWidth(word)}
                        input={input}
                        onChangeEvent={onChangeEvent}
                        correct={correct} trainingExampleId={elem.trainingExample.id}
                        setInputValue={() => {
                        }}
                    />
                </button>

                <span
                    className={`${hint || correct ? "visible animation" : "invisible"} text-2xl text-color-big-text  text-center mt-4`}>
                {targetWord.wordTranslation.wordTo.word}
            </span>
            </motion.div>
        </TrainingBlock>
    )
}
export default AudioTraining