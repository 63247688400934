import {ArrowBack} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {MouseEvent} from "react";

type ButtonBackProps = {
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    backUrl?: string;
    textKey?: string;
    className?: string;
    classNameText?: string;
};

export const ButtonBack = ({onClick: optionalOnClick, backUrl, textKey, className, classNameText}: ButtonBackProps) => {
    const {t} = useTranslation();
    return (
        <button onClick={(e: MouseEvent<HTMLButtonElement>) => {
            optionalOnClick && optionalOnClick(e);
            if (backUrl) {
                window.location.href = backUrl;
            } else {
                window.history.back();
            }
        }}
                className={`${className ?? "lg:pt-6"} pt-0 flex items-center gap-2 rounded-lg cursor-pointer font-bold `}>
            <ArrowBack/>
            <p className={`font-bold ${classNameText}`}>{t(textKey ?? "GoBack")}</p>
        </button>
    )
}