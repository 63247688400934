import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {incrementFinalSequenceIndex} from '../../../../store/training/trainingSlice';
import {
    compareCollapsed,
    completeInputTrainingRef,
    HtmlInputElement,
    playSound,
    revealAnswer
} from '../../../../utils/trainingUtils';
import TrainingBlock from '../TrainingBlock';
import {TrainingTypesProps} from '../../Training';
import TextAreaInput from '../../../Input/TextAreaInput';
import {ReactComponent as SoundIcon} from '../../../../assets/sound.svg'
import {SOUND_TRAINING} from "../../../../constant";
import {motion} from "framer-motion";


const SentenceAudio = ({elem, targetWord}: TrainingTypesProps) => {
    const dispatch = useDispatch();
    const {word} = targetWord.wordTranslation.wordFrom;
    const [input, setInput] = useState<string>()
    const [correct, setCorrect] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);

    const inputRef = useRef<HtmlInputElement>(null)

    const showCorrectAnswer = () => {
        setCorrect(true)
        setSkipped(true)
        revealAnswer(setInput, elem.trainingExample.sentence, elem)
    }

    const handleSkipClick = () => {
        if (correct) {
            setInput("")
            setCorrect(false)
            setSkipped(false)
            dispatch(incrementFinalSequenceIndex())
        } else {
            showCorrectAnswer()
        }
    }


    useEffect(() => {
        inputRef.current && inputRef.current.focus()
        setTimeout(() => {
            playSound(elem.trainingExample.soundUrl)
        }, 500)
    }, [elem.trainingExample.soundUrl])

    useEffect(() => {
        if (compareCollapsed(input || "", elem.trainingExample.sentence)) {

            setCorrect(true)
            completeInputTrainingRef(inputRef)
            playSound(SOUND_TRAINING)
                .then()
                .catch(error => console.error("Error:", error));
            setTimeout(() => {
                playSound(elem.trainingExample.soundUrl).then()
            }, 500)

            if (!skipped) {
                setTimeout(() => {
                    setInput("");
                    setCorrect(false);
                    setSkipped(false);
                    dispatch(incrementFinalSequenceIndex())
                }, 4500)
            }

        }
    }, [input, word, dispatch, elem.trainingExample.soundUrl, elem.trainingExample.sentence, skipped])

    function updateFormValue(value: string) {
        setInput(value)
    }

    return (
        <TrainingBlock isSkipped={skipped} isCorrect={correct} showCorrectAnswer={showCorrectAnswer} setInput={setInput}
                       handleSkipClick={handleSkipClick}>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}}
                        className={`flex flex-col items-center`}>
                <div>
                    <button type="button" value='Replay' className="m-5"
                            onClick={() => playSound(elem.trainingExample.soundUrl)}>
                        <SoundIcon className="w-6 h-6"/>
                    </button>
                </div>
                <TextAreaInput autoFocus={true} disabled={correct} value={input || ""} updateFormValue={updateFormValue}
                               labelStyle=""
                               labelValue=''/>
            </motion.div>
        </TrainingBlock>
    )
}

export default SentenceAudio