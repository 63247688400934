import {SectionCard} from "./SectionCard"
import {SectionFour} from "./SectionFour"
import SectionLanguage from "./SectionLanguage"
import {SectionOne} from "./SectionOne"
import {SectionThree} from "./SectionThree"

const MainPageContent = () => {
    return (
        <div className="flex-1 flex flex-col gap-20 lg:gap-36">
            <SectionOne/>
            <SectionCard/>
            <SectionLanguage/>
            <SectionThree/>
            <SectionFour/>
        </div>
    )
}

export default MainPageContent;