import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../app/store";

export type Language = "ENGLISH" | "GERMAN" | "SPANISH" | "RUSSIAN";

interface LanguageState {
    selectedLearningLanguage: Language;
    selectedNativeLanguage: Language;
}

const initialState: LanguageState = {
    selectedLearningLanguage: "ENGLISH",
    selectedNativeLanguage: "GERMAN",
};

const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setSelectedLearningLanguage: (state, action: PayloadAction<string>) => {
            state.selectedLearningLanguage = action.payload as Language;
        },
        setSelectedNativeLanguage: (state, action: PayloadAction<string>) => {
            state.selectedNativeLanguage = action.payload as Language;
        },
    },
});

export const {setSelectedLearningLanguage, setSelectedNativeLanguage} = languageSlice.actions;

export const selectSelectedLearningLanguage = (state: RootState) =>
    state.language.selectedLearningLanguage;

export const selectSelectedNativeLanguage = (state: RootState) =>
    state.language.selectedNativeLanguage;

export const languageReducer = languageSlice.reducer