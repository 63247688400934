import {createSlice} from '@reduxjs/toolkit'
import {TrainingExampleWithIndex, TrainingState, TrainingType} from './trainingTypes';

export const TrainingInitialState: TrainingState = {
    currentlyTrainedWords: {
        trainingSessionId: 0,
        trainingExamples: []
    },
    audioArray: [],
    translationArray: [],
    phraseConstructionArray: [],
    completeEmptySpacesArray: [],
    sentenceAudioArray: [],
    sentenceTypeArray: [],
    finalTrainingSequence: [],
    mistakesCount: 0,
    hintsCount: 0,
    finalSequenceIndex: 0,
    totalWordsOnTraining: 0,
    totalExercisesCount: 0,
}

const addToArray = (wordToAdd: TrainingExampleWithIndex, array: TrainingExampleWithIndex[], mistake = false) => {
    if (!mistake) {
        const val = array.find(e => e.trainingExample.sentence === wordToAdd.trainingExample.sentence
            && e.trainingExample.identifiedWord === wordToAdd.trainingExample.identifiedWord
            && e.trainingExample.trainingType === wordToAdd.trainingExample.trainingType);

        if (val) return [...array];
    }

    return [...array, wordToAdd]
}

function setHintStatus(finalTrainingSequence: TrainingExampleWithIndex[], index: number, trainingType: TrainingType) {
    return setStatus(finalTrainingSequence, index, trainingType, 'hint');
}

function setSkippedStatus(finalTrainingSequence: TrainingExampleWithIndex[], index: number, trainingType: TrainingType) {
    return setStatus(finalTrainingSequence, index, trainingType, 'skipped');
}

function setStatus(array: TrainingExampleWithIndex[], index: number, trainingType: TrainingType, statusKey: string) {
    return array.map(elem => elem.index === index && elem.trainingExample.trainingType === trainingType ? {
        ...elem,
        [statusKey]: true
    } : {...elem});
}

export const userSlice = createSlice({
    name: 'training',
    initialState: TrainingInitialState,
    reducers: {
        setCurrentlyTrainedWords(state, action) {
            state.currentlyTrainedWords = action.payload;
            state.totalWordsOnTraining = action.payload.length;
        },
        addToAudioArray(state, action) {
            state.audioArray = addToArray(action.payload, state.audioArray);
        },
        addToPhraseConstructionArray(state, action) {
            state.phraseConstructionArray = addToArray(action.payload, state.phraseConstructionArray);
        },
        addToCompleteEmptySpaces(state, action) {
            state.completeEmptySpacesArray = addToArray(action.payload, state.completeEmptySpacesArray);
        },
        addToTranslationArray(state, action) {
            state.translationArray = addToArray(action.payload, state.translationArray);
        },
        setFinalTrainingSequence(state) {
            state.finalTrainingSequence = constructFinalSequence(state.translationArray, state.completeEmptySpacesArray,
                state.phraseConstructionArray, state.audioArray,
                state.sentenceAudioArray, state.sentenceTypeArray);
        },
        incrementFinalSequenceIndex(state) {
            state.finalSequenceIndex += 1;
        },
        incrementHintsNumber(state, action) {
            state.hintsCount += 1;
            state.finalTrainingSequence = setHintStatus(state.finalTrainingSequence, action.payload.index, action.payload.trainingType);
        },
        incrementMistakeNumber(state, action) {
            state.mistakesCount += 1;
            state.finalTrainingSequence = setSkippedStatus(state.finalTrainingSequence, action.payload.index, action.payload.trainingType);
        },
        incrementTotalExercisesCount(state) {
            state.totalExercisesCount += 1;
        },
        addToFinalTrainingSequence(state, action) {
            state.finalTrainingSequence = addToArray(action.payload, state.finalTrainingSequence, true);
        },
        clearTrainingCache() {
            return TrainingInitialState
        },
        addToSentenceAudioArray(state, action) {
            state.sentenceAudioArray = addToArray(action.payload, state.sentenceAudioArray);
        },
        addToSentenceTypeArray(state, action) {
            state.sentenceTypeArray = addToArray(action.payload, state.sentenceTypeArray);
        },
        setFinalTrainingIndex(state, action) {
            state.finalSequenceIndex = action.payload;
        }
    }
})

export const constructFinalSequence = <T extends TrainingExampleWithIndex[]>(
    translationArray: T,
    completeEmptySpacesArray: T,
    phraseConstructionArray: T,
    audioArray: T,
    sentenceAudioArray: T,
    sentenceTypeArray: T,
) => {
    let finalSequence: TrainingExampleWithIndex[] = [];

    finalSequence = finalSequence.concat(translationArray)
    finalSequence = finalSequence.concat(audioArray);
    finalSequence = finalSequence.concat(completeEmptySpacesArray);
    finalSequence = finalSequence.concat(phraseConstructionArray);

    finalSequence = finalSequence.concat(sentenceTypeArray);
    finalSequence = finalSequence.concat(sentenceAudioArray);

    console.log('====================================');
    console.log(finalSequence);
    console.log('====================================');
    return finalSequence;
}

export const trainingReducer = userSlice.reducer;

export const {
    addToAudioArray,
    addToTranslationArray,
    addToPhraseConstructionArray,
    addToCompleteEmptySpaces,
    setCurrentlyTrainedWords,
    setFinalTrainingSequence,
    incrementFinalSequenceIndex,
    clearTrainingCache,
    incrementHintsNumber,
    addToFinalTrainingSequence,
    incrementMistakeNumber,
    incrementTotalExercisesCount,
    addToSentenceAudioArray,
    addToSentenceTypeArray,
    setFinalTrainingIndex

} = userSlice.actions;