import {combineReducers} from "@reduxjs/toolkit";
import {userReducer} from "../store/user/userSlice";
import {vocabularyReducer} from "../store/vocabulary/vocabularySlice";
import {headerReducer} from "../store/headerSlice";
import {rightDrawerReducer} from "../store/rightDrawer/rightDrawerSlice";
import {modalReducer} from "../store/modalSlice";
import {trainingReducer} from "../store/training/trainingSlice";
import {languageReducer} from "../store/languageSlice";


export const rootReducer = combineReducers({
    user: userReducer,
    header: headerReducer,
    vocabulary: vocabularyReducer,
    rightDrawer: rightDrawerReducer,
    modal: modalReducer,
    language: languageReducer,
    training: trainingReducer,
})