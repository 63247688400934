import {createSlice} from '@reduxjs/toolkit'

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        newNotificationMessage: "",  // message of notification to be shown
        newNotificationStatus: 1,   // to check the notification type -  success/ error/ info
        lastNotificationTime: Date.now(),
        notifications: [],
    },

    reducers: {
        removeNotificationMessage: (state) => {
            state.newNotificationMessage = ""
        },
        showNotification: (state, action) => {
            const currentTime = Date.now();
            if (state.lastNotificationTime && currentTime - state.lastNotificationTime < 5000) return;

            state.newNotificationMessage = action.payload.message
            state.newNotificationStatus = action.payload.status
            state.lastNotificationTime = currentTime;
        },
    }
})

export const {removeNotificationMessage, showNotification} = headerSlice.actions

export const headerReducer = headerSlice.reducer