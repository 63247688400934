import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {LOCAL} from "./constants";
import english from "./language/english";
import german from "./language/german";
import spanish from "./language/spanish";

i18n
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        resources: {
            [LOCAL.EN]: {
                translation: english
            },
            [LOCAL.DE]: {
                translation: german
            },
            [LOCAL.ES]: {
                translation: spanish
            }
        },
        lng: LOCAL.EN,
        fallbackLng: [LOCAL.EN],
        keySeparator: false,
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
