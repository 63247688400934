import {ReactElement} from "react";


function UnauthorizedFormComponent({children}: Readonly<{ children: ReactElement[] | ReactElement | undefined }>) {

    return (
        <div className="min-h-screen bg-white lg:bg-[#3C72FF]  lg:bg-opacity-20 flex  items-stretch sm:items-center">
            <div className="mx-auto w-full max-w-xl">
                {children}
            </div>
        </div>
    )
}

export default UnauthorizedFormComponent;